<template>
    <div id="cards-area" class="container">
        <div class="row data-area">
            <div class="col-12 col-md-6 col-lg-4" v-for="(article, i) in articles" :key="i">
                <article-card :article="getArticle(article)"></article-card>
            </div>
        </div>
    </div>
</template>

<script>
import ArticleCard from "../../components/ArticleCard";
import { mapState } from "vuex";

export default {
    props: ["articles", "users"],
    components: {
        "article-card": ArticleCard,
    },
    computed: {
        ...mapState("willyou", ["articlesCategories"]),
    },
    methods: {
        getArticle(article) {
            let userID = article.author;
            let author = this.users.filter((user) => user.id === userID);
            let highestCategory = Math.max(...article.categories);
            let selectedCategory = this.articlesCategories.filter((generalCategory) => generalCategory.id === highestCategory)[0];
            article.selectedCategory = selectedCategory;
            article.author = author && author[0] ? author[0] : article.author;
            return article;
        },
    },
};
</script>
